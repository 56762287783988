import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: '1. What information do we collect?',
    description:
      'we may collect information about the use of Disha Pharma. For instance, we can access usage information regarding how many users are using the website, which geographical regions they are located in, and basic data including users’ language, name, email, device type, and length and frequency of use. The information we receives from website may identifies you, But does not it reveal to us what information you sent to the website or what specific responses the website sent to you.',
  },
  {
    title: '2. How do we use your information?',
    description:
      'we may receive from website personally identifying information about the use of Disha Pharma. For instance, we can access usage information regarding how many users are using the website, which geographical regions they are located in, and basic data including user`s language, name, email, device type, and length and frequency of use. The information we receives from website may identifies you, But does not it reveal to us what information you sent to the website or what specific responses the website sent to you.',
  },
  {
    title: '3. Will your information be shared with anyone?',
    description:
      'When you use Disha Pharma website, you are also using the Google and Vercel Servers, and the Google Privacy Policy describes how Google collects and uses data about your use of the website. You may wish to consult Google’s documentation regarding what information is shared with your Google and how to delete your Google activity.',
  },
  {
    title: '4. Do we use cookies or other tracking technologies?',
    description:
      'Information Collected by Cookies and Other Tracking Technologies:  Cookies, web beacons (also known as “tracking pixels”), embedded scripts, location-identifying technologies, device recognition technologies, and other tracking technologies (“Tracking Technologies”) may be used to collect information about your interactions with the Website',
  },
  {
    title: '5. How long do we keep your information?',
    description:
      'We will not keep your information longer than it is needed. In some instances, the law sets the length of time information has to be kept. We may keep your data longer if we need to retain it for legal, regulatory, or evidential or best practice reasons. We will dispose of all records in a secure way, whether they are on paper or electronic.',
  },
  {
    title:
      '6. How can you review, update or delete the data we collect from you?',
    description:
      'Based on the applicable laws of our & your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please do so by contacting us.',
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
